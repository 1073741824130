<template>
  <div>
    <Card>
      <template #card-body>
        
        <b-table class="mt-3" hover :fields="fields" :items="data">
          <template #cell(id)="data">
            <b-dropdown
              class="p-0 m-0 d-flex"
              size="md"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <fa-icon size="1x" icon="bars" />
              </template>
              <b-dropdown-item @click="show_detail(data.item)" title="Detalhes"
                >Detalhes</b-dropdown-item
              >
              <b-dropdown-item
                @click="$router.push(`/aplicacoes_produtos/${data.item.id}`)"
                title="Detalhes"
              >
                Aplicações/Produtos</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template #cell(value)="data">
             
              <span v-if="data.item.amount_type == 'valor'">R$ {{data.item.amount}}</span>
              <span v-if="data.item.amount_type == 'percentual'">{{data.item.amount}} %</span>            
          </template>
        </b-table>
      </template>
    </Card>
    <!-- <SweetModal
      ref="unity_detail_modal"
      overlay-theme="dark"
      hide-close-button
      width="70%"
    >
      <template #title>
        <span class="text-muted"> Detalhes </span>
      </template>
      <template>
        <b-row class="mb-2">
          <b-col md="8">
            <label>Razão Social</label>
            <b-input :value="unity_details.company_name" disabled></b-input>
          </b-col>
          <b-col md="4">
            <label>CNPJ</label>
            <b-input :value="unity_details.cnpj" disabled></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="8">
            <label> Nome Fantasia </label>
            <b-input :value="unity_details.commercial_name" disabled></b-input>
          </b-col>
          <b-col md="4">
            <label> Nome do Contato </label>
            <b-input :value="unity_details.contact_name" disabled></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="6">
            <label for="">Rua</label>
            <b-input :value="unity_details.street" disabled></b-input>
          </b-col>
          <b-col md="3">
            <label>Número</label>
            <b-input :value="unity_details.number" disabled></b-input>
          </b-col>
          <b-col md="3">
            <label>Complemento</label>
            <b-input :value="unity_details.complement" disabled></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <label for="">Bairro</label>
            <b-input :value="unity_details.district" disabled></b-input>
          </b-col>
          <b-col>
            <label for="">Cidade</label>
            <b-input :value="unity_details.city" disabled></b-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label for="">Email</label>
            <b-input :value="unity_details.email" disabled></b-input>
          </b-col>
        </b-row>
      </template>
      <template #button>
        <div>
          <b-button @click="$refs.unity_detail_modal.close()">Fechar</b-button>
        </div>
      </template>
    </SweetModal> -->
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      unity_details: {},
      fields: [
        {
          key: "id",
          label: "#",
          thStyle: {
            width: "5%",
            textAlign: "center",
          },
          thClass: "text-muted small",
          tdClass: "p-0 text-muted text-center pt-1",
        },
        {
          key: "channel",
          label: "Canal",
          thStyle: {
            width: "40%",
          },
          thClass: "text-muted small",
        },
        {
          key: "value",
          label: "Valor",
          thStyle: {
            width: "10%",
          },
          thClass: "text-muted small",
        },
        {
          key: "expiration_date",
          label: "Data de expiração",
          thStyle: {
            width: "10%",
          },
          thClass: "text-muted small",
        },
        {
          key: "active",
          label: "Ativo",
          thStyle: {
            width: "10%",
          },
          thClass: "text-muted small",
        },
      ],
    };
  },
  methods: {
    async show_detail(data) {
      this.unity_details = {};
      const unity_details = await this.$store.dispatch(
        "unities/get_unity_details",
        data.id
      );
      if (unity_details.length > 0) {
        this.unity_details = unity_details[0];
        console.log(unity_details);
        this.$refs.unity_detail_modal.open();
      }
    },
  },
};
</script>

<style>
</style>